import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import SectionHeader from '@components/ui/typography/SectionHeader'
import SectionDivider from '@components/common/SectionDivider'
import { StaticImage } from 'gatsby-plugin-image'
import TextImageHero from '@components/common/TextImageHero'
import ButtonLink from '@components/ui/buttons/ButtonLink'
import { OpenModalContext } from '@components/layouts/default/Layout'
import modalContactTypes from '@configs/modalContactTypes'

const PricingForSpecialGroupSection = ({ intl }) => {
  function onClick(e, value, type) {
    e.preventDefault()
    value.openContact(type)
  }

  return (
    <SectionWrapper>
      <SectionHeader title={intl.formatMessage({ id: 'pricing.specialGroups.title' })} />
      <OpenModalContext.Consumer>
        {(value) => {
          return (
            <Wrapper>
              <TextImageHero
                section
                title={intl.formatMessage({ id: 'pricing.specialGroups.enterprisePlan.title' })}
                description={[intl.formatMessage({ id: 'pricing.specialGroups.enterprisePlan.text' })]}
                image={
                  <StaticImage
                    alt={intl.formatMessage({ id: 'pricing.specialGroups.enterprisePlan.title' })}
                    src="../../assets/images/pricing/specialGroups/enterprisePlan.png"
                  />
                }
                button={
                  <ButtonLink
                    buttonText={intl.formatMessage({ id: 'pricing.specialGroups.buttonText' })}
                    mdfontsize={'14px'}
                    onClick={(e) => onClick(e, value, modalContactTypes.ENTERPRISE)}
                  />
                }
              />
              <SectionDivider hidePaddingBottom />
              <TextImageHero
                section
                reverse
                title={intl.formatMessage({ id: 'pricing.specialGroups.schoolsAndNonProfitOrganization.title' })}
                description={[intl.formatMessage({ id: 'pricing.specialGroups.schoolsAndNonProfitOrganization.text' })]}
                image={
                  <StaticImage
                    alt={intl.formatMessage({ id: 'pricing.specialGroups.schoolsAndNonProfitOrganization.title' })}
                    src="../../assets/images/pricing/specialGroups/schoolsAndNonProfitOrganizations.png"
                  />
                }
                button={
                  <ButtonLink
                    buttonText={intl.formatMessage({ id: 'pricing.specialGroups.buttonText' })}
                    mdfontsize={'14px'}
                    onClick={(e) => onClick(e, value, modalContactTypes.SCHOOLS)}
                  />
                }
              />
            </Wrapper>
          )
        }}
      </OpenModalContext.Consumer>
    </SectionWrapper>
  )
}

export default injectIntl(PricingForSpecialGroupSection)

const Wrapper = styled.div``
