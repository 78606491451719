import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import { color, breakpoint, transition } from '@configs/utilities'
import arrow from '@assets/images/pricing/switchPeriod/arrow.svg'

const SwitchPeriod = ({ intl, period, onSwitchPeriod }) => {
  const data = useStaticQuery(graphql`
    query {
      allSwitchPeriodJson {
        edges {
          node {
            slug
            text
            handwriting
          }
        }
      }
    }
  `)

  const [activeIndex, setActiveIndex] = useState(1)

  const switchPeriodHandler = (period, index) => {
    setActiveIndex(index)
    onSwitchPeriod(period)
  }

  const findActiveIndex = (periods, period) => {
    const foundPeriodItem = periods.find((item) => item.slug === period)

    return periods.indexOf(foundPeriodItem)
  }

  useEffect(() => {
    const allPeriods = data.allSwitchPeriodJson.edges.map((item) => item.node)
    setActiveIndex(findActiveIndex(allPeriods, period))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper>
      <Switch>
        <ActiveBox style={{ left: `${(100 / data.allSwitchPeriodJson.edges.length) * activeIndex}%` }}></ActiveBox>
        {data.allSwitchPeriodJson.edges.map((item, index) => {
          return (
            <div key={index}>
              <SwitchItem>
                <input
                  onChange={() => switchPeriodHandler(item.node.slug, index)}
                  id={item.node.slug}
                  type="radio"
                  name="plan-time"
                  value={item.node.slug}
                  checked={period === item.node.slug ? item.node.slug : false}
                />
                <label htmlFor={item.node.slug} className={period === item.node.slug ? 'activeLabel' : ''}>
                  {intl.formatMessage({ id: item.node.text })}
                </label>
                <HandwritingWrapper className={`${item.node.slug}Active`}>
                  {period === item.node.slug && (
                    <div className="handwriting">
                      <img src={arrow} alt="" />
                      <span>{intl.formatMessage({ id: item.node.handwriting })}</span>
                    </div>
                  )}
                </HandwritingWrapper>
              </SwitchItem>
            </div>
          )
        })}
      </Switch>
    </Wrapper>
  )
}

export default injectIntl(SwitchPeriod)

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${color.font};
  padding-bottom: 100px;
`

const Switch = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 190px;
  height: 44px;
  background: ${color.white};
  border: 1px solid #bbc9e4;
  border-radius: 48px;

  @media screen and (min-width: ${breakpoint.md}) {
    width: 222px;
  }
`

const ActiveBox = styled.span`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 36px;
  margin: 0 4px;
  transition: ${transition.default};
  background: ${color.blue};
  color: ${color.white};
  border-radius: 100px;
  box-shadow: 0 5px 20px rgba(8, 30, 74, 0.2);

  @media screen and (min-width: ${breakpoint.md}) {
    width: 102px;
  }
`

const SwitchItem = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 36px;
  margin: 0 4px;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.8px;
  color: ${color.font};
  cursor: pointer;

  @media screen and (min-width: ${breakpoint.md}) {
    width: 102px;
  }

  & input {
    position: absolute;
    width: 105px;
    height: 36px;
    opacity: 0 !important;
    cursor: pointer;
  }

  .monthlyActive {
    right: 15px;

    img {
      transform: rotateY(30deg);
      margin: -15px 7px 0 0;

      @media screen and (min-width: ${breakpoint.md}) {
        margin: -15px 10px 0 0;
      }
    }
  }

  .annualActive {
    right: 170px;

    @media screen and (min-width: ${breakpoint.md}) {
      right: 200px;
    }

    .handwriting {
      flex-direction: row-reverse;

      img {
        transform: rotateY(160deg);
        margin: -17px 0 0 8px !important;

        @media screen and (min-width: ${breakpoint.md}) {
          margin: -17px 0 0 5px !important;
          transform: rotateY(180deg);
        }
      }
    }
  }

  .lifetimeActive {
    right: 170px;

    @media screen and (min-width: ${breakpoint.md}) {
      right: 200px;
    }

    .handwriting {
      flex-direction: row-reverse;

      img {
        transform: rotateY(120deg);
        margin: -17px 0 0 -5px !important;

        @media screen and (min-width: ${breakpoint.md}) {
          margin: -17px 0 0 5px !important;
          transform: rotateY(180deg);
        }
      }
    }
  }

  .activeLabel {
    color: ${color.white};
  }
`

const HandwritingWrapper = styled.div`
  position: absolute;
  top: 50px;
  width: 100%;

  .handwriting {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% * 3);
    height: 50px;
    font-family: 'Caveat Brush', cursive;
    font-weight: 300;
    font-size: 20px;
    color: ${color.blue};

    @media screen and (min-width: ${breakpoint.md}) {
      font-size: 24px;
    }
  }
`
