import React from 'react'
import Collapsible from 'react-collapsible'
import styled from 'styled-components'
import angle from '@assets/images/pricing/planCards/angle.svg'
import { color } from '@configs/utilities'

const AccordionPlanCardPricing = (props) => {
  return (
    <AccordionWrapper>
      <Collapsible
        containerElementProps={{ id: `container-element-${props.id}-id-footer` }}
        contentElementId={`content-element-${props.id}-id-footer`}
        triggerElementProps={{ id: `trigger-element-${props.id}-id-footer` }}
        transitionTime={175}
        openedClassName={'collapsible-opened'}
        trigger={
          <Trigger>
            <span>{props.head}</span> <img src={angle} alt="angle" />
          </Trigger>
        }
      >
        <AccordionText>{props.text}</AccordionText>
      </Collapsible>
    </AccordionWrapper>
  )
}

export default AccordionPlanCardPricing

const AccordionWrapper = styled.div`
  width: 100%;
  border-top: 1px solid ${color.footerBorder};

  .collapsible-opened {
    .Collapsible__contentOuter {
      overflow: initial !important;
    }
  }

  .is-open {
    img {
      transform: rotate(180deg);
    }
  }
`

const Trigger = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin-top: 30px;
  padding-top: 10px;
  border-top: 1px solid ${color.grayLight};

  img {
    transition: all 0.15s ease-in-out;
  }
`

const AccordionText = styled.div``
