import React from 'react'
import { HeadingH1, DescriptionDisplay1 } from './Typography'
import styled from 'styled-components'
import { breakpoint } from '@configs/utilities'

const PageHeader = (props) => {
  return (
    <Wrapper>
      <HeadingH1>{props.title}</HeadingH1>
      <DescriptionDisplay1>{props.description}</DescriptionDisplay1>
    </Wrapper>
  )
}

export default PageHeader

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 20px 30px;
  row-gap: 20px;

  @media screen and (min-width: ${breakpoint.xl}) {
    row-gap: 30px;
    padding-bottom: 50px;
  }
`
