import React, { useState } from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import PlanCards from '@components/pages/pricing/planCards/PlanCards'
import SwitchPeriod from '@components/pages/pricing/switches/SwitchPeriod'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import styled from 'styled-components'
import { color } from '@configs/utilities'
import PageHeader from '@components/ui/typography/PageHeader'

const PlanCardsSection = ({ intl }) => {
  const [period, setPeriod] = useState('annual')

  const onSwitchPeriod = (period) => {
    setPeriod(period)
  }

  return (
    <SectionWrapper sectionBackground={color.blueLight} showWaves>
      <PageHeader
        title={intl.formatMessage({ id: 'pricing.hero.title' })}
        description={intl.formatMessage({ id: 'pricing.hero.description' })}
      />
      <SwitchPeriod period={period} onSwitchPeriod={onSwitchPeriod} />
      <PlanCards period={period} />
      <YourCountry>{intl.formatMessage({ id: 'pricing.hero.country' })}</YourCountry>
    </SectionWrapper>
  )
}

export default injectIntl(PlanCardsSection)

const YourCountry = styled.p`
  font-weight: 300;
  font-size: 16px;
  text-align: center;
  margin-top: 50px;
`
