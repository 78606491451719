import React from 'react'
import { injectIntl } from 'gatsby-plugin-react-intl'
import Layout from '@components/layouts/default/Layout'
import PlanCardsSection from '@sections/pricing/PlanCardsSection'
import MoneyBackSection from '@sections/pricing/MoneyBackSection'
import SafeWithUsSection from '@sections/pricing/SafeWithUsSection'
import TestimonialsSection from '@sections/common/TestimonialsSection'
import FrequentlyAskedSection from '@sections/pricing/FrequentlyAskedSection'
import PricingForSpecialGroupsSection from '@sections/pricing/PricingForSpecialGroupsSection'

const Pricing = () => {
  return (
    <Layout pageName="pricing">
      <PlanCardsSection />
      <MoneyBackSection />
      <PricingForSpecialGroupsSection />
      <SafeWithUsSection />
      <TestimonialsSection />
      <FrequentlyAskedSection />
    </Layout>
  )
}

export default injectIntl(Pricing)
